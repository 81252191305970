*{
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: 'Metropolis';
}
@font-face {
  font-family: 'Metropolis';
  src: url('./Metropolis-Regular.otf') format('otf');
}
@font-face {
  font-family: 'MetropolisBold';
  src: url('./Metropolis-Bold.otf') format('otf');
}
@font-face {
  font-family: 'MetropolisXBold';
  src: url('./Metropolis-ExtraBold.otf') format('otf');
  font-weight: bolder;
}
@font-face {
  font-family: 'MetropolisMid';
  src: url('./Metropolis-Medium.otf') format('otf');
}
.business-form{
  width: 100%;
  background-color: #FEF7EC;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  
}

.header{
  width: 100%;
  padding:0 0 20px 0 ;
  background-color: white;
  border-bottom:4px solid #DED7C8;
  display: flex;
  flex-direction: column;
  align-items:center ;
}
.header h1{
  color: #113838;
  width: 100%;
  text-align: center;
  font-family: 'MetropolisXBold';
  font-weight: bolder;
}

/* .header img{
  
} */

.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-bottom: 20px;
}

.input-container{
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 4%;
  
}

.input-container label{
  color: #113838;
  font-weight: 500;
}

.form input ,textarea{
  padding: 15px 7px;
  border-radius: 3px;
  border: 0;
  outline: none;
  background-color: aliceblue;

}
/* textarea{
  
} */

input:focus,textarea:focus{
  border:2px solid #107979;
  background-color: white;
  
}

/* input:not(:placeholder-shown){
  border:2px solid #107979;
  background-color: white;
} */

.submit-button{
  align-self:center ;
  border-radius:40px;
  outline: 0;
  border: 0;
  padding: 8px 25px;
  background-color: #113838;
  color: white;
  font-weight: 100;
  margin-top: 10px;
  font-family: 'MetropolisBold';
}